import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Navbar } from 'react-bootstrap';
import './Header.scss';
import Home from '../../Home';
import logo from '../../../images/logo.png';
import Favicon from 'react-favicon';
import CryptoJS from "crypto-js";
import * as webConst from '../../../utils/WebConstant';
import { Helmet } from 'react-helmet-async';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: '',
      clinic_title: '',
      clinic_short_description: '',
      booking_url: '',
      favicon: '',
      clinic_info: "",
      meta_title: "",
      meta_description: "",
      meta_keyword: ""
    };
  }
  componentDidMount = () => {
    let clinic_info = webConst.getLocalItem("booking_info");
    if (clinic_info && clinic_info !== "" && clinic_info !== null && clinic_info !== "null") {
      var bytes = CryptoJS.AES.decrypt(clinic_info.toString(), 'validi@123456');
      clinic_info = bytes.toString(CryptoJS.enc.Utf8);
      clinic_info = JSON.parse(clinic_info);

      if (clinic_info.logo)
        this.setState({ logo: clinic_info.logo });
      if (clinic_info.clinic_title)
        this.setState({ clinic_title: clinic_info.clinic_title });
      if (clinic_info.clinic_short_description)
        this.setState({ clinic_short_description: clinic_info.clinic_short_description });
      if (clinic_info.favicon)
        this.setState({ favicon: clinic_info.favicon });
      if (clinic_info.meta_title)
        this.setState({ meta_title: clinic_info.meta_title });
      else 
        this.setState({ meta_title: "Booking appointment" });

      if (clinic_info.meta_description)
        this.setState({ meta_description: clinic_info.meta_description });
      if (clinic_info.meta_keyword)
        this.setState({ meta_keyword: clinic_info.meta_keyword });
    }
  }

  componentDidUpdate = prevProps => {
    const { homeSuccess, homeStatus, booking_url } = this.props;
    if (this.state.booking_url !== booking_url)
      this.setState({ 'booking_url': booking_url });
    if (homeStatus === 200) {
      if (prevProps.homeSuccess !== homeSuccess) {
        let clinic_info = {
          logo: homeSuccess.logo,
          clinic_title: homeSuccess.clinic_title,
          clinic_short_description: homeSuccess.clinic_description,
          favicon: homeSuccess.favicon,
          meta_title: homeSuccess.meta_title,
          meta_keyword: homeSuccess.meta_keyword,
          meta_description: homeSuccess.meta_description
        };

        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(clinic_info), 'validi@123456');
        this.setState(clinic_info);
        webConst.setLocalItem("booking_info", ciphertext.toString());
      }
    }
  }

  render() {
    let meta_title = this.state.meta_title ? this.state.meta_title : '';
    let meta_description = this.state.meta_description ? this.state.meta_description : 'validi booking appointment for customer';
    let meta_keyword = this.state.meta_keyword ? this.state.meta_keyword : 'validi booking,booking appointment,validi appointment';
    
    return (
      <Router>
        <Helmet>
          <title>{meta_title}</title>
          <meta name="title" content={meta_title} />
          <meta name="keyword" content={meta_keyword} />
          <meta name="description" content={meta_description} />
        </Helmet>
        {this.state.favicon ? <Favicon url={this.state.favicon} /> : ''}
        <Navbar className="navbar shadow-sm vg-navbar" expand="lg">
          <div className="container">
            <Navbar.Brand href={this.state.booking_url ? this.state.booking_url : '#'} className="navbar-brand">
              {
                this.state.logo ?
                  <div>
                    <img src={this.state.logo ? this.state.logo : logo} width="45" alt="" className="d-inline-block align-middle mr-2 logo" />
                    <span className="app-title font-weight-bold"> {this.state.clinic_title ? this.state.clinic_title : <Translate id="app-title" />}</span>
                    <div className="clinic_description"> {this.state.clinic_short_description ? this.state.clinic_short_description : ''}</div>
                  </div>
                  :
                  ''
              }
            </Navbar.Brand>


            <Navbar.Collapse id="basic-navbar-nav" className="navbar-nav ml-auto">
              <div className="navbar-nav ml-auto">
                {/* <Nav className="mr-auto">
                  <Link className="nav-link" to={routes.HOME_ROUTE}><Translate id="menu.home" /></Link>
                  <Link className="nav-link" to={routes.BOOKING_APPOINTMENT_ROUTE}>Booking</Link>
                </Nav> */}
              </div>
            </Navbar.Collapse>
          </div>
        </Navbar>

        <div className="container mt-4 fh-content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/:clinic_id" exact component={Home} />
          </Switch>
        </div>
      </Router >
    );
  }
}
const mapStateToProps = (state) => {
  return {
    homeSuccess: state.home.success,
    homeFail: state.home.fail,
    homeStatus: state.home.status,
    booking_url: state.appLayout.booking_url
  };
}
const mapDispatchToProps = (dispatch) => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);