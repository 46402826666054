import { BOOKING_HISTORY_SUCCESS, BOOKING_HISTORY_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;


export const getBookingHistoryRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.BOOKING_HISTORY}`,value)
        .then((response) => {
            console.log(response.data);
            if(response.data.responseCode===200)
            {
                dispatch(bookingHistorySuccessResult(response.data.responseBody,response.status));
            }
            else{
                dispatch(bookingHistoryFailResult(response.data.responseMessage,response.data.responseCode));
            }
        })
        .catch((error) => {
            dispatch(bookingHistoryFailResult(error.data,error.status));
        });
    }
};

/*
    API RESULT
 */

export const bookingHistorySuccessResult = (success,status) => ({
    type: BOOKING_HISTORY_SUCCESS,
    success,
    status
});

export const bookingHistoryFailResult = (error,status) => ({
    type: BOOKING_HISTORY_FAIL,
    error,
    status
});
