import { BOOKING_URL } from './constants';

/*
    API REQUEST 
 */


export const setBookingUrlRequest = (value) => {
    return dispatch => {
        dispatch(setBookingUrl(value));
    }
};

export const setBookingUrl = (booking_url) => ({
    type: BOOKING_URL,
    booking_url
});
