/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import appLayoutReducer from '../containers/AppLayoutContainer/reducer';
import homeReducer from '../containers/HomeContainer/reducer';
import bookingReducer from '../containers/BookingContainer/reducer';
import bookingHistoryReducer from '../containers/BookingHistoryContainer/reducer';

import loginReducer from '../containers/Auth/LoginContainer/reducer';
import logoutReducer from '../containers/Auth/LogoutContainer/reducer';

const createReducer = combineReducers({
    appLayout:appLayoutReducer,
    login:loginReducer,
    logout:logoutReducer,
    home:homeReducer,
    booking:bookingReducer,
    bookingHistory:bookingHistoryReducer,
    localize: localizeReducer
});

export default createReducer;
