import React, { Component } from 'react';
import './AppLayout.scss';
import { connect } from 'react-redux';
import Header from './Header';
import Footer from './Footer';

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="app-layout">
        <Header />
        <Footer/>
      </div>
    );
  }
}



function mapStateToProps(state) {
  return {
    
  };
}

const mapDispatchToProps = {  };

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);