import React from 'react';
import Moment from 'react-moment';

export default class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            time: new Date()
        };
    }
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    tick() {
        this.setState({
            time: new Date()
        });
    }
    render() {
        return (
            <span className="App-clock">
                <Moment locale format="DD-MM-YYYY HH:mm:ss">{this.state.time ? this.state.time : new Date()}</Moment>
            </span>
        );
    }
}