import { BOOKING_SUCCESS, BOOKING_FAIL } from './Constants';
import axios from 'axios';
import { withHeader } from '../../service/ApiConfig';
import * as webConst from '../../utils/WebConstant';
const axiosDefaults = require('axios/lib/defaults');
axiosDefaults.baseURL = withHeader.baseURL;
axiosDefaults.headers = withHeader.headers;
axiosDefaults.timeout = withHeader.timeOut;

export const saveBookingRequest = (value) => {
    return dispatch => {
        axios.post(`${webConst.SAVE_APPOINTMENT}`,value)
        .then((response) => {
            if(response.data.responseCode===200)
            {
                dispatch(bookingSuccessResult(response.data.responseBody,response.status));
            }
            else{
                dispatch(bookingFailResult(response.data,response.data.responseCode));
            }
        })
        .catch((error) => {
            console.log("booking error:",error);
            dispatch(bookingFailResult(error.data,error.status));
        });
    }
};

/*
    API RESULT
 */

export const bookingSuccessResult = (success,status) => ({
    type: BOOKING_SUCCESS,
    success,
    status
});

export const bookingFailResult = (error,status) => ({
    type: BOOKING_FAIL,
    error,
    status
});
