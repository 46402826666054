import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getBookingSettingRequest } from '../../containers/HomeContainer/action';
import { saveBookingRequest } from '../../containers/BookingContainer/action';
import { getBookingHistoryRequest } from '../../containers/BookingHistoryContainer/action';
import { setBookingUrlRequest } from '../../containers/AppLayoutContainer/action';
import * as webConst from '../../utils/WebConstant';
import { Translate } from 'react-localize-redux';
// import Moment from 'react-moment';
import 'moment/locale/da';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import SimpleReactValidator from 'simple-react-validator';
import "./Home.scss";
import Clock from '../../utils/Clock';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ContentLoader from 'react-content-loader';

class Home extends Component {

  constructor(props) {
    super(props);

    // let timerID = "";

    this.state = {
      isLoading: true,
      clockDate: new Date(),
      calendarSetting: {},
      branchBox: {},
      key: 0,
      modal: false,
      event: [],
      modalTitle: '',
      appointmentForm: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        zipCode: '',
        address: '',
        city: '',
        branchId: '',
        treatmentId: '',
        description: '',
        clinicId: '',
        amount: 0,
        staffId: '',
        prefixPhone: '45',
        fullMobile: ''
      },
      branchId: '',
      branchName: '',
      clinicId: '',
      error: '',
      backgroundEvents: [],
      appointments: [],
      renderBackgroundEvents: {},
      backgroundDeletedEvents: {},
      startCalendar: '',
      endCalendar: '',
      eventLoaded: false,
      fullCalendar: '',
      vatTax: 0,
      vatTaxAmount: 0,
      isDateSelect: 0,
      link_id: ''
    };

    this.calendarRef = React.createRef();
    this.handleActiveCalendar = this.handleActiveCalendar.bind(this);
    this.handleSubmitSaveAppointment = this.handleSubmitSaveAppointment.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEventRender = this.handleEventRender.bind(this);

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: message => <div className="text-danger">{message}</div>,
      messages: {
        email: 'Skriv venligst en gyldig emailadresse.',
        default: 'Denne felt er påkrævet.'
      }
    });
    this.wrapper = React.createRef();
  }

  componentDidMount() {
    if (this.props && this.props.location && this.props.location.search) {
      this.props.history.push(this.props.location.pathname);
    }
    const today = moment();
    const start = today.startOf('week').format("YYYY-MM-DD");
    const end = today.endOf('week').format("YYYY-MM-DD");
    this.setState({ 'startCalendar': start });
    this.setState({ 'endCalendar': end });
    let _this = this;
    setTimeout(function () {
      _this.getBookingSetting();
    }, 1);

  }

  async getBookingSetting() {
    this.setState({ 'isLoading': true });
    const params = new URLSearchParams();
    let link_id = this.props.match && this.props.match.params.clinic_id ? this.props.match.params.clinic_id : 0;
    params.append('clinic_id', link_id);
    params.append('link_id', link_id);
    if (this.state.link_id === "")
      this.setState({ link_id: link_id });
    this.props.getBookingSettingRequest(params);
    this.props.setBookingUrlRequest(window.location.href);
  }

  componentDidUpdate(prevProps) {
    const { homeSuccess, homeFail, homeStatus, bookingSuccess, bookingFail, bookingStatus, bookingHistorySuccess, bookingHistoryFail, bookingHistoryStatus } = this.props;
    if (bookingStatus === 200) {
      if (prevProps.bookingSuccess !== bookingSuccess) {
        if (this.state.isLoading) {
          let hostedPaymentPageUrl = bookingSuccess.hostedPaymentPageUrl;
          this.setState({
            isLoading: false,
            modal: false
          });

          if (typeof hostedPaymentPageUrl !== undefined && typeof hostedPaymentPageUrl !== "undefined" && hostedPaymentPageUrl !== "") {
            webConst.setLocalItem("booking_id", bookingSuccess.id);
            webConst.setLocalItem("is_new_booking", true);
            window.location.href = hostedPaymentPageUrl;
          }
          else {
            webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);
            webConst.setLocalItem("is_new_booking", false);
          }

        }
      }
    }
    else {
      if (prevProps.bookingFail !== bookingFail) {
        let message = "";
        if (bookingFail.responseBody) {
          if (bookingFail.responseBody.id)
            this.setState({ appointmentForm: { ...this.state.appointmentForm, ['id']: bookingFail.responseBody.id } });
          message = bookingFail.responseMessage;
        }

        if (message)
          webConst.toaster(webConst.TOAST_ERROR, message);
        else
          webConst.toaster(webConst.TOAST_ERROR, <Translate id="alert-message.something-wrong" />);

        if (this.state.isLoading) {
          this.setState({
            isLoading: false,
          });
        }
      }
    }

    if (homeStatus === 200) {
      if (prevProps.homeSuccess !== homeSuccess) {
        if (this.state.isLoading) {
          this.setState({
            isLoading: false
          });

          let isBlank = true;
          let branchBox = this.state.branchBox;
          let clinic_id = 0;

          if (homeSuccess.clinic_id) {
            clinic_id = homeSuccess.clinic_id;
            this.setState({ "clinicId": clinic_id });
            this.setState({ vatTax: homeSuccess.booking_vat_tax });
          }

          if (homeSuccess.branches) {
            let branch_id = 0;
            homeSuccess.branches.map((item, key) => {
              let branch = item.id;
              if (isBlank) {
                branch_id = branch;
                branchBox[branch] = true;
                this.setState({ branchId: branch, branchName: item.name });
                isBlank = false;
              }
              else {
                branchBox[branch] = item.name;
              }
              return "";
            });

            this.setState({ branchBox: branchBox });
          }
        }
      }
    }
    if (prevProps.homeFail !== homeFail) {
      if (this.state.isLoading) {
        this.setState({
          isLoading: false,
        });
      }
    }
  }

  // event to handle input change
  handleChange = (name, value) => {
    this.setState({ 'error': '' });
    this.setState({ appointmentForm: { ...this.state.appointmentForm, [name]: value } });
    if (name === 'treatmentId') {
      let appointmentForm = this.state.appointmentForm;
      appointmentForm["treatmentId"] = value;
      let _this = this;
      let vatTax = this.state.vatTax;
      vatTax = vatTax ? vatTax : 0;
      if (value === "" && vatTax) {
        _this.setState({ vatTaxAmount: 0 });
        appointmentForm["amount"] = "0.00";
      }
      else {

        this.props.homeSuccess.treatments.map(function (item, index) {

          if (value === item.id) {
            if (vatTax > 0) {
              appointmentForm["vatTaxAmount"] = (parseFloat(item.price) * parseFloat(vatTax)) / 100;
              _this.setState({ vatTaxAmount: (parseFloat(item.price) * parseFloat(vatTax)) / 100 });
              console.log("vatTaxAmount", _this.state.vatTaxAmount);
            }
            appointmentForm["amount"] = parseFloat(item.price).toFixed(2);
          }
          return "";
        });
      }
      this.setState({ appointmentForm: { ...this.state.appointmentForm, appointmentForm } });
    }
  }

  handleChangeMobile = (phone, data, formattedValue) => {
    //console.log(phone.slice(data.dialCode.length));
    var appointmentForm = this.state.appointmentForm;
    appointmentForm['fullMobile'] = formattedValue;
    appointmentForm['mobile'] = phone.slice(data.dialCode.length);
    appointmentForm['prefixPhone'] = data.dialCode;
    this.setState({ appointmentForm: { ...this.state.appointmentForm, appointmentForm } });
  }

  handleActiveCalendar(branchId, branchName) {
    let branchBox = this.state.branchBox;
    this.setState({ branchId: branchId, branchName: branchName });
    this.setState({ appointmentForm: { ...this.state.appointmentForm, ['amount']: "0.00" } });
    this.setState({ renderBackgroundEvents: {} });

    Object.keys(branchBox).map(key => {
      if (branchId === key && branchBox[branchId] === true) {
        branchBox[key] = false;
        let isShow = Object.values(branchBox).filter(function (status) {
          return status === false;
        });
        if (isShow)
          branchBox[key] = true;
      }
      else if (branchId === key && branchBox[branchId] === false) {
        branchBox[key] = true;
      }
      else
        branchBox[key] = false;
      return branchBox[key];
    });
    this.setState({ branchBox: branchBox });
    const today = moment();
    const start = today.startOf('week').format("YYYY-MM-DD");
    const end = today.endOf('week').format("YYYY-MM-DD");
    this.setState({ 'startCalendar': start });
    this.setState({ 'endCalendar': end });
    // this.getBookingHistories(this.state.clinicId, branchId);
  }


  handleTest = (data) => {
    console.log("handleTest", data);
  }

  handleDateClick = (selectInfo) => {
    //for mobile only because touching is not working in calendar
    let hasTouchscreen = 'ontouchstart' in window;
    if (!hasTouchscreen)
      return false;
    console.log("handleDateClick");

    let _this = this;
    this.validator.hideMessages();
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect() // clear date selection
    let selectedDatetime = moment(selectInfo.dateStr);
    let bookingStartEvent = selectedDatetime;
    let startBookingDate = bookingStartEvent.format("YYYY-MM-DD");
    let startBookingTime = bookingStartEvent.format("HH:mm:ss");
    let endBookingTime = "";
    selectInfo.view.calendar.getEvents().map(function (event) {
      // console.log(event.extendedProps.type,event.extendedProps);
      if (event.extendedProps.type === 'available') {
        let startEvent = moment(event.startStr);
        let endEvent = moment(event.endStr);
        let startDate = startEvent.format("YYYY-MM-DD");
        let startTime = startEvent.format("HH:mm:ss");
        let endTime = endEvent.format("HH:mm:ss");
        endBookingTime = event.extendedProps.endTime;
        //console.log(startBookingDate +'=='+ startDate +'&&'+ startBookingTime +'=='+ startTime+'&&'+endBookingTime+'=='+endTime);
        if (startBookingDate === startDate && startBookingTime === startTime && endBookingTime === endTime) {
          let appointmentForm = _this.state.appointmentForm;
          appointmentForm["id"] = '';
          appointmentForm["staffId"] = event.extendedProps.staffId;
          appointmentForm["startDate"] = startBookingDate;
          appointmentForm["startTime"] = startBookingTime;
          appointmentForm["endTime"] = endBookingTime;
          _this.setState({ appointmentForm: appointmentForm });

          _this.setState({ modal: true, modalTitle: <Translate id="home.create-appointment-title" /> });
          _this.setState({ isDateSelect: 1 });
        }
      }
    });
  }

  handleDateSelect = (selectInfo) => {
    //create booking in desktop
    let hasTouchscreen = 'ontouchstart' in window;
    if (hasTouchscreen)
      return false;
    console.log("handleDateSelect");
    let _this = this;
    this.validator.hideMessages();
    this.setState({ isDateSelect: 1 });
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect() // clear date selection
    let bookingStartEvent = moment(selectInfo.startStr);
    let bookingEndEvent = moment(selectInfo.endStr);
    let startBookingDate = bookingStartEvent.format("YYYY-MM-DD");
    let startBookingTime = bookingStartEvent.format("HH:mm:ss");
    let endBookingTime = bookingEndEvent.format("HH:mm:ss");

    selectInfo.view.calendar.getEvents().map(function (event) {
      console.log(event);
      if (event.extendedProps.type === 'available') {
        let startEvent = moment(event.startStr);
        let endEvent = moment(event.endStr);
        let startDate = startEvent.format("YYYY-MM-DD");
        let startTime = startEvent.format("HH:mm:ss");
        let endTime = endEvent.format("HH:mm:ss");

        if (startBookingDate === startDate && startBookingTime === startTime && endBookingTime === endTime) {
          let appointmentForm = _this.state.appointmentForm;
          appointmentForm["id"] = '';
          appointmentForm["staffId"] = event.extendedProps.staffId;
          appointmentForm["startDate"] = startBookingDate;
          appointmentForm["startTime"] = startBookingTime;
          appointmentForm["endTime"] = event.extendedProps.endTime;
          _this.setState({ appointmentForm: appointmentForm });

          _this.setState({ modal: true, modalTitle: <Translate id="home.create-appointment-title" /> });
        }
      }
    });
  }

  handleEventClick = (clickInfo) => {
    const title = clickInfo.event.title;
    // console.log("handleEventClick", title);
  }

  handleEvents = (info) => {
    // if (moment(this.state.startCalendar).format("YYYY-MM-DD") != moment(info.activeStart).format("YYYY-MM-DD")) {
    //this.getBookingHistories(this.state.clinicId, this.state.branchId);
    // }
    // console.log("handleEvents");
  }

  handleViewDidMount = (info) => {
    // if (moment(this.state.startCalendar).format("YYYY-MM-DD") !== moment(info.view.activeStart).format("YYYY-MM-DD")) {
    //   this.setState({ startCalendar: info.view.activeStart });
    //   this.setState({ endCalendar: info.view.activeEnd });
    // }
    console.log("handleViewDidMount");
  }

  handleEventSet = (events) => {
    //console.log("handleEventSet");
  }

  renderEventContent(eventInfo, event) {
    //console.log("eventinfo",eventInfo.event);
    return (
      <div>
        <b>{eventInfo.timeText}</b>
        <small>{eventInfo.event.title}</small>
      </div>
    );
  }

  handleEventRender = function (event) {
    console.log("handleEventRender", event);
  }


  handleEventDidMount = (info) => {
    console.log("handleEventDidMount");
  }


  handleSelectAllow = function (info) {
    // if(disallowedDays.includes(moment(info.startStr).format("YYYY-MM-DD HH:mm")))
    //   return false;
    // else
    //   return true;
    var check = moment(info.start);
    var today = moment();
    if (check.format("YYYY-MM-DD") >= today.format("YYYY-MM-DD")) {
      return true;
    }
    else {
      return false;
    }
  }

  createBranches = function () {
    const { homeSuccess, homeFail, homeStatus } = this.props;
    let calendar_max_time = moment(homeSuccess.to_time, "HH:mm").add("1", "hours").format("HH:mm");

    if (homeSuccess.branches) {
      var branchTitle = [];
      var branchContent = [];
      var contents = [];
      //let _this = this;
      homeSuccess.branches.map((item, key) => {

        branchTitle.push(<label key={"branch-title-" + item.id} onClick={() => this.handleActiveCalendar(item.id, item.name)} className={"cursor-pointer " + (this.state.branchId === item.id ? 'branch-label active-branch-label' : 'branch-label')}>
          {item.name}
        </label>);
        branchContent.push(<div key={"branch-content-" + item.id} >
          {
            (this.state.branchId === item.id &&
              <div>
                <h6 className="text-primary mb-4 mt-4">{item.name}</h6>
                <FullCalendar
                  id={item.id}
                  ref={this.calendarRef}
                  initialView='timeGridWeek'
                  height={"auto"}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'title',
                    // center: '',
                    right: 'today,timeGridWeek,timeGridDay,prev,next'
                  }}
                  events={
                    webConst.WEB_SERVICE_URL + '/' + webConst.BOOKING_EVENTS + '?clinicId=' + this.state.clinicId + '&branchId=' + this.state.branchId + '&link_id=' + this.state.link_id
                  }
                  /*events={
                    webConst.WEB_SERVICE_URL + '/' + webConst.BOOKING_EVENTS + '?clinicId=' + this.state.clinicId + '&branchId=' + this.state.branchId + '&link_id=' + this.state.link_id
                    {
                    url: this.state.branchId === item.id ? webConst.WEB_SERVICE_URL + '/' + webConst.BOOKING_EVENTS + '?clinicId=' + this.state.clinicId + '&branchId=' + this.state.branchId + '&link_id=' + this.state.link_id : '',
                    startParam: 'start',
                    endParam: 'end',
                    loading: function (status) {
                      _this.setState({loading:status});
                    },
                    success: function (response) { 
                      let newBookingId = webConst.getLocalItem("booking_id");
                      response.map(function (item, index) {
                          if (webConst.getLocalItem("is_new_booking") === "true" && newBookingId == item.id) {
                            webConst.toaster(webConst.TOAST_SUCCESS, <Translate id="home.booking-save-message" />);
                            webConst.setLocalItem("is_new_booking", false);
                            webConst.setLocalItem("booking_id", "");
                          }
                      });
                      return response;
                    }
                  }
                  }
                  */
                  dateClick={this.handleDateClick}
                  select={this.handleDateSelect}
                  eventContent={this.renderEventContent} // custom render function
                  allDaySlot={false}
                  locale={homeSuccess.clinic_language}
                  slotMinTime={homeSuccess.from_time}
                  slotMaxTime={calendar_max_time}
                  slotLabelFormat={{
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: (homeSuccess.calendar_time_format.match(/A/g) ? true : false),
                  }}
                  eventTimeFormat={{ hour12: (homeSuccess.calendar_time_format.match(/A/g) ? true : false), hour: "numeric", minute: "2-digit" }}
                  dayMaxEvents={true}
                  editable={true}
                  selectable={true}
                  firstDay={"1"}
                />

              </div>)
          }
        </div>);
      });
    }
    if (branchTitle) {
      contents.push(<div key="branch-box" className="branch-box">{branchTitle}</div>);
    }

    if (branchContent)
      contents.push(branchContent);
    return contents;
  }
  handleModalShowHide() {
    this.setState({ modal: !this.state.modal });
    this.setState({ appointmentForm: !this.state.modal });
    this.setState({ appointmentForm: { ...this.state.appointmentForm, ['amount']: "0.00" } });
  }

  async handleSubmitSaveAppointment(event) {
    event.preventDefault();
    if (this.validator.allValid()) {
      const { appointmentForm } = this.state
      let params = new URLSearchParams();
      params.append('id', appointmentForm.id);
      params.append('firstName', event.target.firstName.value);
      params.append('lastName', event.target.lastName.value);
      params.append('email', event.target.email.value);
      params.append('mobile', appointmentForm.mobile);
      params.append('prefixPhone', appointmentForm.prefixPhone);
      params.append('branchId', event.target.branchId.value);
      params.append('treatmentId', event.target.treatmentId.value);
      params.append('startDate', event.target.startDate.value);
      params.append('startTime', event.target.startTime.value);
      params.append('endTime', event.target.endTime.value);
      params.append('amount', appointmentForm.amount);
      params.append('clinicId', event.target.clinicId.value);
      params.append('description', event.target.description.value);
      params.append('city', event.target.city.value);
      params.append('address', event.target.address.value);
      params.append('zipCode', event.target.zipCode.value);
      params.append('staffId', appointmentForm.staffId);
      let link_id = this.props.match && this.props.match.params.clinic_id ? this.props.match.params.clinic_id : 0;
      params.append('link_id', link_id);
      this.props.saveBookingRequest(params);
      this.setState({ 'isLoading': true });
      this.validator.hideMessages();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  treatmentOption() {
    const { homeSuccess, homeFail, homeStatus } = this.props;
    if (homeSuccess && homeSuccess.treatments) {
      return homeSuccess.treatments.map(function (item, index) {
        return [{ label: item.id, value: item.treatment_name }];
      });
    }
    else {
      return [];
    }
  }

  render() {
    const { homeSuccess, homeFail, homeStatus } = this.props;
    const { appointmentForm } = this.state;
    
    let branch_tabs = "";
    if (homeSuccess) {
      branch_tabs = this.createBranches();
    }

    return (
      <div className="vg-card-header" ref={this.wrapper}>
        <div className="fh-main-client">
          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header vg-main-header">
                  <div className="vg-card-title">
                    <div className="row">
                      <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <Translate id="home.title" />
                      </div>
                      <div className="col-lg-4 text-right d-none d-sm-block d-md-block d-lg-block d-xl-block">
                        <Clock />
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12 d-lg-block d-sm-none d-md-none d-lg-none d-xl-none mt-2">
                        <Clock />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {!this.state.isLoading ?
                    <div>
                      {
                        homeStatus === 403 ?
                          <div className="alert alert-danger">
                            {homeFail && homeFail.responseMessage ? homeFail.responseMessage : ''}
                          </div>
                          :
                          branch_tabs ? branch_tabs :
                            <div className="alert alert-danger">
                              <Translate id="home.appontment-timeslot-available-message" />
                            </div>
                      }
                    </div>
                    : <ContentLoader
                      speed={2}
                      width={"100%"}
                      height={600}
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="10" y="18" rx="3" ry="3" width="100%" height="50" />
                      <rect x="10" y="76" rx="3" ry="3" width="100%" height="35" />
                      <rect x="15" y="139" rx="3" ry="3" width="100%" height="519" />
                    </ContentLoader>
                  }
                  <Modal show={this.state.modal} animation={false}>
                    <form className="text-left well" onSubmit={this.handleSubmitSaveAppointment}>
                      <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
                        <Modal.Title>{this.state.modalTitle}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {
                          !this.state.isLoading ?
                            <div className="col-md-12">
                              <div className="form-group">
                                <label><Translate id="home.select-treatment" />*</label>
                                <select name="treatmentId" className="form-control" onChange={(e) => { this.handleChange(e.target.name, e.target.value) }}>
                                  <Translate>
                                    {({ translate }) => <option value="">{translate('home.select-treatment')}</option>}
                                  </Translate>
                                  {homeSuccess && homeSuccess.treatments ?
                                    homeSuccess.treatments.map((obj) =>
                                      <option key={"treatment-" + obj.id} value={obj.id}>{obj.treatment_name}</option>
                                    ) : ''}
                                </select>
                                {this.validator.message('treatmentId', this.state.appointmentForm.treatmentId, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.first-name" />*</label>
                                <input type="text" name="firstName" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.firstName ? appointmentForm.firstName : ''} />
                                {this.validator.message('firstName', appointmentForm.firstName, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.last-name" />*</label>
                                <input type="text" name="lastName" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.lastName ? appointmentForm.lastName : ''} />
                                {this.validator.message('lastName', appointmentForm.lastName, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.email" />*</label>
                                <input type="text" name="email" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.email ? appointmentForm.email : ''} onBlur={() => {
                                  { this.validator.message('email', appointmentForm.email, 'required|email') }
                                }} />
                                {this.validator.message('email', appointmentForm.email, 'required')}
                                {this.validator.message('email', appointmentForm.email, 'email')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.mobile" />*</label>
                                <PhoneInput
                                  preferredCountries={['dk', 'us']}
                                  placeholder={"+45 90 90 90 90"}
                                  country={'dk'}
                                  inputProps={{ id: 'client-mobile', 'name': 'mobile', autoComplete: "off", required: true }}
                                  enableSearch={true}
                                  countryCodeEditable={false}
                                  value={appointmentForm.fullMobile ? appointmentForm.fullMobile : ''}
                                  onChange={(phone, data, event, formattedValue) => this.handleChangeMobile(phone, data, formattedValue)}
                                />
                                {this.validator.message('mobile', appointmentForm.mobile, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.city" />*</label>
                                <input type="text" name="city" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.city ? appointmentForm.city : ''} />
                                {this.validator.message('city', appointmentForm.city, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.zipcode" />*</label>
                                <input type="text" name="zipCode" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.zipCode ? appointmentForm.zipCode : ''} />
                                {this.validator.message('zipCode', appointmentForm.city, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.address" />*</label>
                                <input type="text" name="address" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.address ? appointmentForm.address : ''} />
                                {this.validator.message('address', appointmentForm.address, 'required')}
                              </div>
                              <div className="form-group">
                                <label><Translate id="home.appiontment-description" /></label>
                                <textarea name="description" className="form-control" onChange={e => this.handleChange(e.target.name, e.target.value)} value={appointmentForm.description ? appointmentForm.description : ''} />
                              </div>

                              {appointmentForm.startDate && this.state.appointmentForm.amount > 0 ?
                                <div className="mt-5">
                                  <table className="table table-striped table-sm">
                                    <thead>
                                      <tr className="table-secondary">
                                        <th><Translate id="home.branch" /></th><th><Translate id="home.date" /></th><th><Translate id="home.slot-time" /></th><th><Translate id="home.amount" /></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>{this.state.branchName}</td>
                                        <td>{moment(appointmentForm.startDate).format("DD-MM-YYYY")}</td>
                                        <td>{appointmentForm.startTime} to {appointmentForm.endTime}</td>
                                        <td className="text-right">{this.state.appointmentForm.amount} {process.env.REACT_APP_CURRENCY}</td>
                                      </tr>
                                      <tr>
                                        <th colSpan="3"><Translate id="home.sub-total" /></th>
                                        <td className="text-right">{this.state.appointmentForm.amount} {process.env.REACT_APP_CURRENCY}</td>
                                      </tr>
                                      <tr>
                                        <th colSpan="3"><Translate id="home.vat-tax" /></th>
                                        {this.state.appointmentForm.amount > 0 && this.state.vatTax > 1 ?
                                          <td className="text-right">{parseFloat(this.state.vatTaxAmount).toFixed(2)} {process.env.REACT_APP_CURRENCY}</td>
                                          : <td className="text-right">0.00 {process.env.REACT_APP_CURRENCY}</td>
                                        }
                                      </tr>
                                      {this.state.appointmentForm.amount > 0 ?
                                        <tr>
                                          <th colSpan="3"><Translate id="home.grand-total" /></th>
                                          <th className="text-right">{(parseFloat(this.state.appointmentForm.amount) + parseFloat(this.state.vatTaxAmount)).toFixed(2)} {process.env.REACT_APP_CURRENCY}</th>
                                        </tr>
                                        : ''}
                                    </tbody>
                                  </table>
                                </div>
                                : ''
                              }

                              <input type="hidden" name="branchId" className="form-control" value={this.state.branchId} />
                              <input type="hidden" name="startDate" className="form-control" value={appointmentForm.startDate ? appointmentForm.startDate : ''} />
                              <input type="hidden" name="endDate" className="form-control" value={appointmentForm.endDate ? appointmentForm.endDate : ''} />
                              <input type="hidden" name="startTime" className="form-control" value={appointmentForm.startTime ? appointmentForm.startTime : ''} />
                              <input type="hidden" name="endTime" className="form-control" value={appointmentForm.endTime ? appointmentForm.endTime : ''} />
                              <input type="hidden" name="clinicId" className="form-control" value={homeSuccess && homeSuccess.clinic_id ? homeSuccess.clinic_id : ''} />
                            </div>
                            :
                            <ContentLoader
                              speed={2}
                              width={"100%"}
                              height={600}
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              <rect x="14" y="18" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="79" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="199" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="141" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="258" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="318" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="381" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="439" rx="3" ry="3" width="95.%" height="35" />
                              <rect x="14" y="500" rx="3" ry="3" width="95.%" height="77" />
                            </ContentLoader>
                        }
                      </Modal.Body>
                      <Modal.Footer>
                        <Button type="submit" variant="primary" disabled={this.state.isLoading}>
                          <Translate id="book" />
                        </Button>
                        <Button variant="secondary" onClick={() => this.handleModalShowHide()}>
                          <Translate id="back" />
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    homeSuccess: state.home.success,
    homeFail: state.home.fail,
    homeStatus: state.home.status,
    bookingSuccess: state.booking.success,
    bookingFail: state.booking.fail,
    bookingStatus: state.booking.status,
    bookingHistorySuccess: state.bookingHistory.success,
    bookingHistoryFail: state.bookingHistory.fail,
    bookingHistoryStatus: state.bookingHistory.status,
  };
}
const mapDispatchToProps = { getBookingSettingRequest, saveBookingRequest, getBookingHistoryRequest, setBookingUrlRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Home);
