import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import './App.scss';
import englishTranslation from './utils/locale/en.json';
import danishTranslation from './utils/locale/da.json';
import { ToastContainer } from 'react-toastify';
import AppLayout from './components/AppLayout/AppLayout';
import './scss/_variables.scss';

class App extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      "en":true,
      "da":false,
    };
    let languages=[];
    
    languages=[
      { name: 'Denish', code: 'da' },
      { name: 'English', code: 'en' },
    ];
    
    this.props.initialize({
      languages: languages,
      translation: {},
      options: { renderToStaticMarkup }
    });


    this.props.addTranslationForLanguage(englishTranslation, 'en');
    this.props.addTranslationForLanguage(danishTranslation, 'da');
  }

  render() {
    return (
      <div>
        <AppLayout/>
        <ToastContainer />
      </div>
    );
  }
}

export default withLocalize(App);
