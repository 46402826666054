import { BOOKING_SUCCESS, BOOKING_FAIL } from './Constants';

const initialState = {
  isLoading: true
};
export default function bookingReducer(state = initialState, action) {
  switch (action.type) {
    case BOOKING_SUCCESS:
      return {
        ...state,
        success: action.success,
        fail: '',
        status: action.status
      };
    case BOOKING_FAIL:
      return {
        ...state,
        success: '',
        fail: action.error,
        status: action.status
      };
    default:
      return state;
  }
}