import { BOOKING_URL } from './constants';

const initialState = {
  booking_url: ""
};

export default function appLayoutReducer(state = initialState, action) {
  switch (action.type) {
    case BOOKING_URL:
      return Object.assign({}, state, {
        booking_url: action.booking_url
      });
    default:
      return state;
  }
}